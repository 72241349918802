import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle'
import './common_assets/font-awsom/font-awesome.css'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
const HomeRoutes = lazy(() => import('./routes/HomeRoutes'));
const AdminRoutes = lazy(() => import('./routes/AdminRoutes'));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <Suspense fallback={null}>
      <BrowserRouter basename='/'>
        <HomeRoutes />
      </BrowserRouter>
      <BrowserRouter basename='/admin'>
        <AdminRoutes />
      </BrowserRouter>
      <ToastContainer />
    </Suspense>
  </LocalizationProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
